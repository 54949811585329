.root {
    background-color: #F7F7F7;
    border-top: 0.0625rem solid #BEBEC2;
}

.container {
    margin: 0 auto;
    min-height: 3.5625rem;
    max-width: 80rem;
    padding: 0 0.75rem;
    display: flex;
    flex-direction: row;
    gap: 3rem;
    justify-content: space-between;
    align-items: center;
}

.info {
    font-family: FortumSans, arial, sans-serif;
    font-size: 0.875rem;
    line-height: 1.05rem;
}

.navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3rem;
}

.link {
    font-family: FortumSans, arial, sans-serif;
    font-size: 0.875rem;
    line-height: 1.05rem;
    color: #377A6C;
    text-decoration: none;
}

.up {
    font-family: FortumSans, arial, sans-serif;
    font-size: 0.875rem;
    line-height: 1.05rem;
    color: #377A6C;
    text-decoration: none;
    padding: 0;
    display: inline-flex;
    align-items: center;
    background: none;
    border: none;
    gap: 0.5rem;
}

.upIcon {
    border-radius: 50%;
    border: 1px solid currentColor;
}

.link:hover,
.up:hover {
    text-decoration: underline;
}
