@import '../../styles/main.scss';

.historyTable {
    margin-top: 40px;

    table {
        width: 100%;
    }

    th {
        background-color: $forest-green-color;
        color: #fff;
        border: none !important;
    }

    th,
    td {
        padding: {
            top: 5px;
            bottom: 5px;
        }

        height: unset;
    }

    td {
        border-bottom: 1px solid #E8E8EB;
        vertical-align: middle;

    }

    tr {
        border: {
            left: 1px solid #E8E8EB;
            right: 1px solid #E8E8EB;
        }
    }
}

.actionTableCellIcon {
    width: 24px;
    height: 24px;
    padding: {
        top: 1px;
        right: 6px;
    }
}

.actionTableCellLinkContainer {
    margin-left: 30px;
}

.actionTableCellRow {
    display: flex;
    flex-direction: row;
}

.oldValue {
    color: #B83E4D;
    text-decoration-line: line-through;
}
.newValue {
    color: #377A6C;
}
