$forest-green: #377a6c;

// @font-face {
//     font-family: fortum-sans;
//     src: url("../../assets/FortumSans/WOFF-2-PS/FortumSansWeb-Regular.woff2") format("woff2");
//     font-weight: 400;
// }

// @font-face {
//     font-family: fortum-sans;
//     src: url("../../assets/FortumSans/WOFF-2-PS/FortumSansWeb-Light.woff2") format("woff2");
//     font-weight: 300;
// }

@mixin button-reset {
    border: none;
    color: inherit;
    font: inherit;
    padding: 0;
    background: none;
}

.DSROverviewTable {
    font-family: FortumSans, arial, sans-serif;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.DSROverviewTableProcedures {
    margin-top: 26px; // Fix
    border-top: 48px solid $forest-green;
    border-right: 0.5px solid #999999;
    flex: 1 0 auto;
}

.DSROverviewTableGraph {
    flex: 0 0 auto;
}

.DSROverviewTableProcedure {
}

.DSROverviewTableProcedureHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 25px;
    border-bottom: 1px solid #999999;

    height: 23px;
    overflow: hidden;
}

.DSROverviewTableProcedureHeaderNameContainer {
    display: flex;
}

.DSROverviewTableProcedureHeaderName {
    @include button-reset;
    font-size: 12px;
    line-height: 23px;
    color: $forest-green;
    cursor: pointer;

    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
}

.DSROverviewTableProcedureHeaderNameChervon {
    width: 20px;
    height: 20px;
}

.DSROverviewTableProcedureHeaderMenu {
    @include button-reset;
    font-size: 12px;
    line-height: 14.4px;
    padding: 5px 4.3px;
    color: $forest-green;
    cursor: pointer;
}

.DSROverviewTableProcedureHeaderMenu:hover {
    text-decoration: underline;
    color: #5AC37D;
}

.DSROverviewTableProcedureGroup {
    font-size: 14px;
    line-height: 23px;
    border-bottom: 1px solid #999999;
    padding-left: 24px;
    color: #4a4a4a;
}

.DSROverviewTableGraphDatesBackground {
    fill: #316e61;
}

.DSROverviewTableGraphDate,
.DSROverviewTableGraphHour {
    font-family: FortumSans, arial, sans-serif;
    font-weight: 300;
    font-size: 12px;
    fill: #fff;
}

.DSROverviewTableGraphItemControlCode {
    font-family: FortumSans, arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    fill: #000;
}

.DSROverviewTableGraphTimeText {
    font-family: FortumSans, arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    fill: #fff;
}

.DSROverviewTableGraphHoursBackground {
    fill: #377a6c;
}

.DSROverviewTableGraphHorizontalLine,
.DSROverviewTableGraphVerticaDateLine {
    stroke-width: 1;
    stroke: #999999;
}

.DSROverviewTableGraphVerticalLine {
    stroke-width: 1;
    stroke: #999999;
    opacity: 0.3;
}

.DSROverviewTableGraphProcedureBase {
    fill: #c9c9c9;
}

.DSROverviewTableGraphProcedureItemAccepted {
    fill: #c9c9c9;
}

.DSROverviewTableGraphProcedureItemCompleted {
    fill: #5ac37d;
}

.DSROverviewTableGraphProcedureItemToBeAccepted {
    fill: #ffc663;
}

.DSROverviewTableGraphProcedureItemToBeRunning {
    fill: #c9c9c9;
}

.DSROverviewTableGraphProcedureItemPlanned {
    fill: #f5e150;
}

.DSROverviewTableGraphProcedureItemRejected {
    fill: #b27ee0;
}

.DSROverviewTableGraphProcedureItemTerminated {
    fill: #B27EE0;
}

.DSROverviewTableGraphProcedureItemError {
    fill: #c9c9c9;
}

.DSROverviewTableGraphProcedureItemUndefined {
    fill: #f0f;
}

.DSROverviewTableGraphProcedurePartialyCompleted {
    fill: #5ac37d;
}

.DSROverviewTableGraphProcedureChangePositive {
    fill: #EB6437;
}

.DSROverviewTableGraphProcedureChangeNegative {
    fill: #406AAD;
}

.DSROverviewTableGraphDayHover {
    fill: transparent;
}

.DSROverviewTableGraphDayHover:hover {
    fill: rgba(0,0,0,0.08);
}

.DSROverviewTableGraphTime {
    stroke: #377A6C;
    stroke-width: 1;
    stroke-dasharray: 4;
}

.DSROverviewTableGraphTimeBubble {
    fill: #377A6C;
}

.DSROverviewTableErrorIcon {
    cursor: pointer;
}

