@import '../../styles/main.scss';

.details {
    min-width: 70rem;
}

.rejection,
.terminationReason,
.terminationConsequences {
    min-width: 60rem;
}

.detailsButtonsContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.rejectionButtonsContainer {
    justify-content: space-between;
}

.buttonCustomIcon {
    margin-right: 12px;
}

.buttonWithCustomIcon span {
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonWithCustomIcon:hover .buttonCustomIcon line {
    transition: color 200ms ease;
    stroke: #1f453d;
}

.buttonWithCustomIcon:hover .buttonCustomIcon circle,
.buttonWithCustomIcon:hover .buttonCustomIcon path {
    transition: color 200ms ease;
    fill: #1f453d;
}
