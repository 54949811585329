.ProcedureName {
    font-size: 0.75rem !important;
}

.ProcedureContainer {
    border-width: 1px;
    border-style: dashed;
    border-color: #999999;
    cursor: move;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ProcedureContainerMenu {
    position: absolute;
    width: 56px;
    height: 22px;
    background-color: #999999;
    border-radius: 5px;
    cursor: auto;
    padding-top: 1px;
    padding-left: 12px;
    padding-right: 12px;
}

.ProcedureGraph {
    flex: 0 0 auto;
}

.ProcedureGraphProcedureItemPlanned {
    fill: #f5e150;
}

.ProcedureGraphProcedureChangePositive {
    fill: #EB6437;
}

.ProcedureGraphProcedureChangeNegative {
    fill: #406AAD;
}

.ProcedureGraphItemControlCode {
    font-family: FortumSans, arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    fill: #41414A;
}

.ProcedureGraphItemControlCodeBold {
    font-weight: 700;
}

.ProcedureDropZone {
    fill: #EB6437;
}

.ProcedureDropZone1 {
    border-width: 1px;
    border-style: solid;
    border-color: #000000;
    fill: #EB6437;
}
.ProcedurePopOverText {
    font-weight: 400;
    font-size: 14px;
    fill: #41414A;
}

.sectionRow {
    margin-left: 5px;
    display: flex;
    align-items: baseline;
}

.dotsBeetwen {
    flex: 1;
    border-bottom: 1px dotted #B6B8BD;
}
