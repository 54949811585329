
.modal {
    min-width: 54rem;
}

.modalManual {
    min-width: 67rem;
}

.heading {
    font-size: 24px!important;
    font-weight: 700!important;
}

.modalContent {
    padding: 40px 32px;
}

.inputField {
    width: 180px;
    max-width: 180px;
}

.ProcedureGraph {
    flex: 0 0 auto;
}

.DSRInputTableGraphHorizontalLine {
    stroke-width: 1;
    stroke: #999999;
}

.DSRInputTableGraphHour {
    font-family: FortumSans, arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    fill: #4a4a4a;
}

.DSRHRadioButtonGroup {
    p {
        font-weight: 400;
        font-size: 16px;
    }
    label {
        padding-top: 0;
    }
}

.DSRRadioButtonGroup {
    max-width: 143px;
    width: 143px;
    overflow: hidden;
    position: relative;
    p {
        font-weight: 400;
        font-size: 16px;
    }
}

.DSRRadioButtonGroupDisabled {
    max-width: 143px;
    width: 143px;
    overflow: hidden;
    position: relative;
    p {
        color: #BEBEC2;
        font-weight: 400;
        font-size: 16px;
    }
}

.DSRRadioButtonGroupWrapper {
    p {
        font-weight: 400;
    }
}

.DSRConfigureGroupContainer {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 131px;
}

.DSRConfigureTableGraphProcedureItem {
    fill: #f5e150;
}
.DSRConfigureTableGraphProcedureChangePositive {
    fill: #EB6437;
}
.DSRConfigureTableGraphProcedureChangeNegative {
    fill: #406AAD;
}

.DSRConfigureTableGraphItemControlCode {
    font-family: FortumSans, arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    fill: #41414A;
}

.DSRConfigureSaveButton {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.DSRConfigureOrderButtonContainer {
    position: absolute;
    right: 0;
}
