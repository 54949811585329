@import '../../styles/main.scss';

.triggerText {
    cursor: pointer;
    padding-bottom: 22px;
}

.root {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 12px 24px;
}

.items {
    width: 100%;
    display: flex;
}

.legendText {
    font-size: 1rem !important;
}
