.HeaderContainer {
    color: #FFFFFF;
    background-color: #377A6C;
}
.HeaderContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 5rem;
}
.HeaderLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1;
}

.HeaderRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1;
    .HeaderMenuLink {
        margin-right: 48px;
        &:visited {
            color: #FFFFFF;
        }
    }
    .HeaderMenuLinkActive {
        display: inline-block;
        height: 44px;
        border-bottom-color: #FFFFFF;
        border-bottom-width: 8px;
        border-bottom-style: solid;
    }
}
.AuthWidget {
    display: flex;
    align-items: center;
}
.AuthButtonWrapper {
    font-family: FortumSans,arial,sans-serif;
    background: none;
    border: none;
    font-size: 0.875rem;
    height: 5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    color: #FFFFFF;
    transition: color 300ms;
    transition-timing-function: cubic-bezier(0.5,0,0,1);
    &:hover {
        color: #C9C9C9;
    }
}
.AuthTextWrapper {
    display: flex;
    flex-direction: column;
    padding-right: 1rem;
    align-items: flex-end;
}
.AuthName {
    text-align: right;
}
.AuthIcon {
    box-sizing: content-box;
    color: #FFFFFF;
    background-color: #377A6C;
    border-radius: 1.25rem;
    padding: 0.5rem;
}
