@import '../../styles/main.scss';

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
}

.colorBox {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.scenarioColorBox {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border: 1px solid $light-gray-color;
}

.scenarioColorBoxInner {
    height: 50%;
    background-color: #fff;
}

.label {
    font-size: 1rem !important;
}
