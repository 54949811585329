.HPT_container {
    height: 664px;
    overflow: auto;
    margin-bottom: 32px;
    margin-left: -30px;
    padding-left: 30px;
    position: relative;
}

.HPT {
    border-spacing: 0;
    font-family: FortumSans, arial, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    user-select: none;
    thead {
        th {
            color: #ffffff;
            background-color: #377A6C;
            text-align: center;
            height: 96px;
            padding: 0;
            font-weight: 400;
            position: sticky;
            top: 0;
            z-index: 3;
            .HPTCover {
                position: absolute;
                left: -30px;
                width: 30px;
                height: 120px;
                background-color: #ffffff;
                top: 0;
            }
        }
    }
    tbody {
        tr:hover {
            background-color: #f2f2f2;
        }
        td {
            color: #4A4A4A;
            text-align: center;
            vertical-align: middle;
            height: 23px;
            padding: 0;
            border-bottom: 1px solid #999999;
        }
        .HPTDayHead {
            position: sticky;
            top: 96px;
            z-index: 3;
            td {
                font-weight: 400;
                background-color: #C3D8D3;
            }
            &:hover {
                td {
                    background-color: #C3D8D3;
                }
            }
        }
    }
}

.HPTColMedium {
    width: 113px;
}

.HPTColSmall {
    width: 98px;
}

.HPTWheatherContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    div {
        margin-left: 12px;
    }
}

.HPTCopiedValue {
    color: #c9c9c9;
}

.HPTVariationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    fill: #4a4a4a;
    div {
        margin-right: 12px;
    }
}

.HPTVariationContainerMarked {
    color: #d25564;
    fill: #d25564;
}

.HPTProcedure {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 23px;
    position: relative;
}

.HPTFieldWithTooltip {
    display: flex;
    align-items: center;
    .HPTTooltipIcon {
        flex: 1;
    }
}

.HPTTooltipIcon {
    position: absolute;
    left: 0;
    top: 1px;
}

.HPTPopoverTable {
    border-spacing: 0;
    font-family: FortumSans, arial, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16.8px;
    width: 390px;
    td {
        color: #4A4A4A;
        border-bottom: 1px dashed #B6B8BD;
    }
    tr.last {
        td {
            border-bottom: none;
        }
    }
}

.HPTFieldWithTooltip::after {
    flex: 1;
    content: '';
}

.HPTProcedurePlus {
    background-color: #EB6437;
    width: 24px;
}

.HPTProcedureMinus {
    background-color: #406AAD;
    width: 24px;
}

.HPTProcedureHour {
    width: 74px;
}

.HPTProcedureCompletedItem {
    width: 74px;
    background-color: #5ac37d;
    position: absolute;
}

.HPTProcedurePlanned {
    background-color: #F5E150;
}

.HPTProcedureCompleted {
    background-color: #5ac37d;
}

.HPTProcedureToBeRunning {
    background-color: #c9c9c9;
}

.HPTProcedureToBeAccepted {
    background-color: #ffc663;
}

.HPTProcedureAccepted {
    background-color: #c9c9c9;
}

.HPTProcedureError {
    background-color: #c9c9c9;
}

.HPTProcedureRejected {
    background-color: #B27EE0;
}

.HPTProcedureTerminated {
    background-color: #B27EE0;
}

.HPTProcedureUndefined {
    background-color: #f0f;
}

.HPTHeadSource {
    color: #F5E150;
    font-weight: 700;
}

.HPTHeadAddDesc {
    font-style: italic;
    font-weight: 300;
}

.HPTDivContentEditable {
    padding-top: 4.5px;
    padding-bottom: 4.5px;
    &:focus, &:focus-visible {
        padding-top: 2.5px;
        padding-bottom: 2.5px;
    }
}

.HPTDivSelected {
    border: 2px dashed black;
    padding-top: 2.5px;
    padding-bottom: 2.5px;
}

.HPTDivEdited {
    border: 2px solid #EB6437;
    padding-top: 2.5px;
    padding-bottom: 2.5px;
}

.HPTCurrentTimeContainer {
    position: relative;
    height: 2px;
    top: -5px;
}

.HPTTimeLine {
    position: absolute;
    width: 1280px;
    height: 1px;
    border-bottom: 1px dashed #377A6C;
}

.HPTTimeBox {
    position: absolute;
    width: 37px;
    height: 21px;
    border-radius: 4px;
    left: -30px;
    background-color: #377A6C;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.HPTTimeBoxArrow {
    position: absolute;
    left: 6px;
    width: 0;
    height: 0;
    border-left: 9px solid #377A6C;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
}
