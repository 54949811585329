.dataTime {
    font-size: 12px !important;
    color: #A3A3A3 !important;
    text-align: right !important;
}

.NetworkStatusGoogleMap__container {
    height: 100%;
}

.NetworkStatusMapReadoutPzas {
    font-family: FortumSans, arial, sans-serif;
    color: #4A4A4A;
}

.NetworkStatusMapReadoutPpow {
    font-family: FortumSans, arial, sans-serif;
    color: #636363;
}

.NetworkStatusMapReadoutTzas {
    font-family: FortumSans, arial, sans-serif;
    color: #B83E4D;
}

.NetworkStatusMapReadoutTpow {
    font-family: FortumSans, arial, sans-serif;
    color: #2D9CDB;
}
