$white-color: #fcf5ed;
$dark-color: #402f2b;
$light-color: #e6d5c3;
$medium-color: #977978;
$alert-color: #cb492a;
$light-black-color: #706e72;
$black-color: #414042;
$forest-green-color: #377A6C;
$dark-gray-color: #41414A;
$light-gray-color: #BEBEC1;
