
.ProductionPlanGraphHorizontalLine {
    stroke-width: 1;
    stroke: #999999;
}

.ProductionPlanGraphHour {
    font-family: FortumSans, arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    fill: #4a4a4a;
}

.ProductionPlanGraphDescriptionContainer {
    width: 169px;
    max-width: 169px;
}

.ProductionPlanGraphDescription {
    margin-right: 14px;
    text-align: right;
}

.ProductionPlanGraphDescriptionF {
    margin-top: 23px;
}

.ProductionPlanGraphLine {
    margin-left: 10px;
    margin-right: 6px;
    border-bottom: 1px solid #000000;
    width: 100%;
    max-width: 16px;
    height: 17px;
    position: relative;
}

.ProductionPlanGraphLineOne {
    height: 9px;
}

.ProductionPlanGraphPeakBoilers {
    border-bottom-color: #78A0D4;
}

.ProductionPlanGraphCircle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #000000;
    top: 12px;
    left: 3px;
    position: absolute;
}

.ProductionPlanGraphSquare {
    width: 10px;
    height: 10px;
    background-color: #000000;
    top: 12px;
    left: 3px;
    position: absolute;
}

.ProductionPlanGraphSquareOne {
    top: 4px;
}

.ProductionPlanGraphOrange {
    background-color: #EB6437;
}

.ProductionPlanGraphBrown {
    background-color: #9C4122;
}

.ProductionPlanGraphGreen {
    background-color: #3E9458;
}

.ProductionPlanGraphBlue {
    background-color: #406AAD;
}

.ProductionPlanGraphText {
    font-family: FortumSans, arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    fill: #4A4A4A;
}

.ProductionPlanGraphLineSolid {
    fill: none;
    stroke: #000000;
    stroke-width: 1;
}

.ProductionPlanGraphLineDashed {
    fill: none;
    stroke: #000000;
    stroke-width: 1;
    stroke-dasharray: 5, 2;
}

.ProductionPlanGraphLinePeakBoilers {
    fill: none;
    stroke: #78A0D4;
    stroke-width: 0.8;
}

.ProductionPlanGraphValue {
    font-family: FortumSans, arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
}

.ProductionPlanGraphTime {
    stroke: #377A6C;
    stroke-width: 1;
    stroke-dasharray: 4;
}

.ProductionPlanGraphTimeBubble {
    fill: #377A6C;
}

.ProductionPlanGraphDSRText {
    font-family: FortumSans, arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    fill: #FFFFFF;
}

.ProductionPlanGraphDSR0Text {
    font-family: FortumSans, arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    fill: #4A4A4A;
}

.ProductionPlanGraphProcedureItemAccepted {
    fill: #c9c9c9;
}

.ProductionPlanGraphProcedureItemCompleted {
    fill: #5ac37d;
}

.ProductionPlanGraphProcedureItemToBeAccepted {
    fill: #ffc663;
}

.ProductionPlanGraphProcedureItemToBeRunning {
    fill: #c9c9c9;
}

.ProductionPlanGraphProcedureItemPlanned {
    fill: #f5e150;
}

.ProductionPlanGraphProcedureItemRejected {
    fill: #b27ee0;
}

.ProductionPlanGraphProcedureItemTerminated {
    fill: #B27EE0;
}

.ProductionPlanGraphProcedureItemError {
    fill: #c9c9c9;
}

.ProductionPlanGraphProcedureItemUndefined {
    fill: #f0f;
}

.triggerText {
    cursor: pointer;
}

.ProductionPlanGraphErrorIcon {
    cursor: pointer;
}
