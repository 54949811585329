.button {
    display: flex;
    background: #377A6C;
    align-items: center;
    padding-left: 6px;
    padding-right: 6px;
    outline: none;
    border-width: 2px;
    border-style: solid;
    border-color: #377A6C;
    border-radius: 16px;
    margin-bottom: 16px;
    height: 32px;
}

.iconContainer {
    display: inline-flex;
    margin-left: 4px;
}

.p {
    font-family: FortumSans, arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #fff;
    margin: 0;
    line-height: 14px;
}

.icon {
    fill: #fff;
}
