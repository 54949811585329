.DSRDetailsTableGraph {
    flex: 0 0 auto;
}

.DSRDetailsTableGraphHeaderBackground {
    fill: #316e61;
}

.DSRDetailsTableGraphHorizontalLine {
    stroke-width: 1;
    stroke: #999999;
}

.DSRDetailsTableGraphLabel {
    font-family: FortumSans, arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    fill: #fff;
}

.DSRDetailsTableGraphHour {
    font-family: FortumSans, arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    fill: #4a4a4a;
}

.DSRDetailsTableGraphItemControlCode {
    font-family: FortumSans, arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    fill: #41414A;
}

.DSRDetailsTableGraphProcedureItemAccepted {
    fill: #c9c9c9;
}

.DSRDetailsTableGraphProcedureItemCompleted {
    fill: #5ac37d;
}

.DSRDetailsTableGraphProcedureItemToBeAccepted {
    fill: #ffc663;
}

.DSRDetailsTableGraphProcedureItemError {
    fill: #c9c9c9;
}

.DSRDetailsTableGraphProcedureItemRejected {
    fill: #b27ee0;
}

.DSRDetailsTableGraphProcedureItemTerminated {
    fill: #b27ee0;
}

.DSRDetailsTableGraphProcedureItemToBeRunning {
    fill: #c9c9c9;
}

.DSRDetailsTableGraphProcedureItemPlanned {
    fill: #f5e150;
}

.DSRDetailsTableGraphProcedureChangePositive {
    fill: #EB6437;
}

.DSRDetailsTableGraphProcedureChangeNegative {
    fill: #406AAD;
}

.DSRDetailsTableErrorIcon {
    cursor: pointer;
}
