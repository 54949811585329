.DSRInputTableGraphHeadBackground {
    fill: #316E61;
}

.ProcedureGraph {
    flex: 0 0 auto;
}

.ProcedureDropZone {
    fill: #EB6437;
}

.DSRInputTableGraphLabel {
    font-family: FortumSans, arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    fill: #fff;
}

.DSRInputTableGraphHour {
    font-family: FortumSans, arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    fill: #4a4a4a;
}

.DSRInputTableGraphHorizontalLine {
    stroke-width: 1;
    stroke: #999999;
}

.DSROverviewTableGraphProcedureBase {
    fill: #c9c9c9;
}

.DSROverviewTableGraphProcedureItemAccepted {
    fill: #c9c9c9;
}

.DSROverviewTableGraphProcedureItemCompleted {
    fill: #5ac37d;
}

.DSROverviewTableGraphProcedureItemToBeAccepted {
    fill: #ffc663;
}

.DSROverviewTableGraphProcedureItemToBeRunning {
    fill: #c9c9c9;
}

.DSROverviewTableGraphProcedureItemPlanned {
    fill: #f5e150;
}

.DSROverviewTableGraphProcedureItemRejected {
    fill: #b27ee0;
}

.DSROverviewTableGraphProcedureItemTerminated {
    fill: #B27EE0;
}

.DSROverviewTableGraphProcedureItemError {
    fill: #c9c9c9;
}

.DSROverviewTableGraphProcedureItemUndefined {
    fill: #f0f;
}

.DSROverviewTableGraphProcedurePartialyCompleted {
    fill: #5ac37d;
}

.DSROverviewTableGraphProcedureChangePositive {
    fill: #EB6437;
}

.DSROverviewTableGraphProcedureChangeNegative {
    fill: #406AAD;
}

.DSRDetailsTableGraphItemControlCode {
    font-family: FortumSans, arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    fill: #41414A;
}

.DSROverviewTableGraphTimeText {
    font-family: FortumSans, arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    fill: #fff;
}

.DSROverviewTableGraphTime {
    stroke: #377A6C;
    stroke-width: 1;
    stroke-dasharray: 4;
}

.DSROverviewTableGraphTimeBubble {
    fill: #377A6C;
}

.DSRInputTableErrorIcon {
    cursor: pointer;
}
