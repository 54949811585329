.WT_container {
    height: 664px;
    overflow: auto;
    margin-bottom: 32px;
    margin-left: -30px;
    padding-left: 30px;
    position: relative;
}
.WT {
    border-spacing: 0;
    font-family: FortumSans, arial, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    user-select: none;
    thead {
        th {
            color: #ffffff;
            background-color: #377A6C;
            text-align: center;
            height: 96px;
            padding: 0;
            font-weight: 400;
            position: sticky;
            top: 30px;
            z-index: 3;
            &.WTColFirst {
                top: 0;
                width: 130px;
            }
            &.WTColCity {
                top: 0;
                height: 30px;
                background-color: #326E61;
                span {
                    padding-left: 16px;
                }
            }
            .WTCover {
                position: absolute;
                left: -30px;
                width: 30px;
                height: 120px;
                background-color: #ffffff;
                top: 0;
            }
            .WTColBrake {
                height: 96px;
            }
        }
    }
    tbody {
        tr:hover {
            background-color: #f2f2f2;
        }
        td {
            color: #4A4A4A;
            text-align: center;
            vertical-align: middle;
            height: 23px;
            padding: 0;
            border-bottom: 1px solid #999999;
            position: relative;
            .WTColBrake {
                height: 24px;
            }
            &.WTColLeft {
                text-align: left;
                span {
                    padding-left: 10px;
                }
                &.WTColWithBrake {
                    span {
                        padding-left: 26px;
                    }
                }
            }
        }
        .WTDayHead {
            position: sticky;
            top: 126px;
            z-index: 3;
            td {
                font-weight: 400;
                background-color: #C3D8D3;
            }
            &:hover {
                td {
                    background-color: #C3D8D3;
                }
            }
        }
    }
}

.WTColSmallWithBrake {
    width: 109px;
    div.content {
        padding-left: 16px;
    }
}

.WTColBrake {
    position: absolute;
    left: 0;
    width: 16px;
    background-color: #ffffff;
    top: 0;
    & + .WTWeatherContainer {
        padding-left: 16px;
    }
}

.WTColSmall {
    width: 93px;
}

.WTWeatherContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    div {
        margin-left: 12px;
    }
}

.WTHeadSource {
    color: #F5E150;
    font-weight: 700;
}

.WTHeadAddDesc {
    font-style: italic;
    font-weight: 300;
}
